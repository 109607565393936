type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'start' | 'end' | 'match-parent';
type AlignItems = 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
type JustifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'initial' | 'inherit';
type RelAttribute =
  | 'alternate'
  | 'author'
  | 'bookmark'
  | 'external'
  | 'help'
  | 'license'
  | 'next'
  | 'nofollow'
  | 'noopener'
  | 'noreferrer'
  | 'prev'
  | 'search'
  | 'tag';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

type HexColor = `#${string}`;
type RGBColor = `rgb(${number},${number},${number})`;
type RGBAColor = `rgba(${number},${number},${number},${number | number})`;
type Color = HexColor | RGBColor | RGBAColor;

type UnitPx = `${number}px` | 0;

interface SiteElementBaseSettings {
  styles: {
    align: AlignItems;
    justify: JustifyContent;
    text: TextAlign;
    'm-element-margin': string;
    'z-index': number;
    'grid-row': string;
    'grid-column': string;
    'm-grid-row': string;
    'm-grid-column': string;
  }
}

interface SiteElementBasePosition {
  top: number;
  left: number;
  width: number;
  height: number;
  fontSize?: number;
}

export interface SiteElementGridSocialIconLink {
  svg: string;
  icon: string;
  link?: string;
  url?: string;
}
export interface SiteElementBase {
	type: SiteElementType;
  initialElementId: string;
	mobile: SiteElementBasePosition;
	desktop: SiteElementBasePosition;
  animation?: {
		name: string;
		type: string;
	},
  settings: SiteElementBaseSettings;
  aiData?: {
    type?: string;
    [k: string]: unknown;
  };
}

export interface SiteElementBaseButton extends SiteElementBase {
  href: string;
	rel: RelAttribute;
	target: '_blank'|'_self'|'_parent'|'_top';
	content: string;
	linkType: LinkType;
	fontColor: Color;
	fontWeight: number;
	borderColor: Color;
	borderWidth: number;
	borderRadius: number;
	fontColorHover: Color;
	backgroundColor: Color;
	borderColorHover: Color;
	backgroundColorHover: Color;
	settings: SiteElementBaseSettings & {
		type: string;
    isFormButton?: boolean;
	},
  linkedPageId?: string,
}

export interface SiteElementGridButton extends SiteElementBaseButton {
  type: 'GridButton';
}

export interface SiteElementGridStripeButton extends SiteElementBaseButton {
  type: 'GridStripeButton';
  priceId: string;
  paymentType: string;
}

export interface SiteElementGridEcommerceButton extends SiteElementBaseButton {
  type: 'GridEcommerceButton';
  productId: string;
	productVariantId: string;
}

export interface SiteElementGridMap extends SiteElementBase {
  type: 'GridMap';
  settings: SiteElementBaseSettings & {
    src: string;
    'm-element-margin': string;
  };
}

export interface SiteElementGridVideo extends SiteElementBase {
  type: 'GridVideo';
  settings: SiteElementBaseSettings & {
    jpg: string;
    src: string;
    webp: string;
    provider: 'youtube' | 'vimeo';
    initialSrc: string;
  }
}

export interface SiteElementGridTextBox extends SiteElementBase {
  type: 'GridTextBox';
  content: string;
}

export interface SiteElementGridShape extends SiteElementBase {
  type: 'GridShape';
  svg: string;
  color: Color;
  shape: string;
}

export interface SiteElementGridImage extends SiteElementBase {
  type: 'GridImage';
  rel: RelAttribute;
  href: string;
  mobile: SiteElementBasePosition & { borderRadius: string };
  desktop: SiteElementBasePosition & { borderRadius: string };
  settings: SiteElementBaseSettings & {
    alt: string;
		path: string;
		origin: string;
		clickAction: string;
  }
}

export interface SiteElementGridInstagramFeed extends SiteElementBase {
  type: 'GridInstagramFeed';
  settings: SiteElementBaseSettings & {
    username?: string;
    apiVersion?: 'instagram_basic_display' | 'instagram_api_with_instagram_login';
    'item-gap': UnitPx;
    'm-item-gap': UnitPx;
    'items-per-row': number;
    'm-items-per-row': number;
    'm-element-margin': `${UnitPx} ${UnitPx} ${UnitPx} ${UnitPx}`;
  },
  'item-count': number;
}

export interface SiteElementGridSocialIcons extends SiteElementBase {
  type: 'GridSocialIcons';
  useBrandColors: boolean;
  links: SiteElementGridSocialIconLink[];
  settings: SiteElementBaseSettings & {
    'icon-size': UnitPx;
    'icon-color': Color;
    'icon-spacing': JustifyContent,
    'icon-direction': FlexDirection;
    'icon-color-hover': Color,
    'm-element-margin': `${UnitPx} ${UnitPx} ${UnitPx} ${UnitPx}`,
    'space-between-icons': UnitPx;
  }
}

export interface SiteElementGridGallery extends SiteElementBase {
  type: 'GridGallery';
  images: {
    alt: string;
    path: string;
    origin: 'assets' | 'unsplash' | 'other';
  }[];
  desktop: SiteElementBasePosition & {
    columnGap: number;
		columnCount: number;
  };
  mobile: SiteElementBasePosition & {
    columnGap: number;
		columnCount: number;
  },
  settings: SiteElementBaseSettings & {
    layout: string,
		imageClickAction: 'lightbox' | 'none',
  }
}

export interface SiteElementGridEmbed extends SiteElementBase {
  type: 'GridEmbed';
  content: string;
  settings: SiteElementBaseSettings & {
    styles: {
      height: number;
      heightMobile?: number;
    }
  }
}

export interface SiteElementSearchBar extends SiteElementBase {
  type: 'SearchBar';
  placeholderText: string;
  noResultsText: string;
  fillColor: Color;
  fillColorHover: Color;
  resultItemHoverColor: Color;
  textAndIconColor: Color;
  textAndIconColorHover: Color;
  borderColor: Color;
  borderColorHover: Color;
  borderRadius: number;
  fontFamily: string;
}

export interface SiteElementGridForm extends SiteElementBase {
  type: 'GridForm';
  formId: string;
  mobile: SiteElementBasePosition & {
    inputTextSize: number;
    labelTextSize: number;
    submitButtonFontSize: number;
    formElementsVerticalSpacing: number; 
  };
  desktop: SiteElementBasePosition & {
    inputTextSize: number;
    labelTextSize: number;
    submitButtonFontSize: number;
    formElementsVerticalSpacing: number; 
  };
  settings: SiteElementBaseSettings & {
    theme: string;
    schema: {
      id: string;
      svg: string;
      name: string;
      type: string;
      fieldType: string;
      inputLabel: string;
      validation: string[][];
      placeholder: string;
      validationType: string;
      'validation-messages': {
        email: string;
        required: string;
      };
    }[];
    styles: {
      formSpacing: string;
    };
    successMessage: string;
  };
  formPadding: number;
  inputFillColor: Color;
  inputTextColor: Color;
  labelTextColor: Color;
  formBorderWidth: number;
  innerBackground: {
    color: Color;
    image: string;
    current: string;
  };
  formBorderRadius: number;
  inputBorderColor: Color;
  inputBorderWidth: number;
  submitButtonData: SiteElementGridButton;
  inputBorderRadius: number;
  formBackgroundColor: string;
  inputFillColorHover: Color;
  inputTextColorHover: Color;
  inputBorderColorHover: Color;
  submitButtonFontColor: Color;
  submitButtonBorderColor: Color;
  submitButtonBorderRadius: number;
  submitButtonFontColorHover: Color;
  submitButtonBackgroundColor: Color;
  submitButtonBorderColorHover: Color;
  submitButtonBackgroundColorHover: Color;
};
export interface SiteTypographyStyles {
  'font-family': string;
  'font-size': string;
  'm-font-size': string;
  'font-style': string;
  'font-weight':
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'normal'
  | 'bold';
  'line-height': string;
  'text-decoration': string;
  'text-transform': string;
  'letter-spacing': string | number;
  color?: string;
  'color-hover'?: string;
}

export interface SiteGridButtonStyle {
  'padding-y': string;
  'm-padding-y': string;
  'padding-x': string;
  'm-padding-x': string;
  'font-family': string;
  'font-size': string;
  'm-font-size': string;
  'font-style': string;
  'font-weight': 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  'line-height': string;
  'text-decoration': string;
  'text-transform': string;
  'letter-spacing': string;
  'background-color-null': string;
  'background-color-null-hover': string;
  'border-null': string;
  'border-null-hover': string;
  'border-radius': number;
  'box-shadow-null': string;
  'box-shadow-null-hover': string;
  'box-shadow-x': string;
  'box-shadow-x-hover': string;
  'box-shadow-blur': string;
  'box-shadow-blur-hover': string;
  'box-shadow-spread': string;
  'box-shadow-spread-hover': string;
  'box-shadow-y': string;
  'box-shadow-y-hover': string;
  'box-shadow-color': string;
  'box-shadow-color-hover': string;
  'transition-duration': string;
  'transition-timing-function': string;
}

export type SiteBlockType = 'BlockSlideshow'
  | 'BlockGrid'
  | 'BlockNavigation'
  | 'BlockBlogList'
  | 'BlockBlogHeader'
  | 'BlockEcommerceProduct'
  | 'BlockEcommerceModal'
  | 'BlockEcommerceProductList'
  | 'BlockLayout'
  | 'BlockImageSlideshow'
  | 'BlockStickyBar';

export type SiteElementType = 'GridGallery'
  | 'GridTextBox'
  | 'GridShape'
  | 'GridImage'
  | 'GridButton'
  | 'GridMap'
  | 'GridVideo'
  | 'GridInstagramFeed'
  | 'GridSocialIcons'
  | 'GridForm'
  | 'GridEmbed'
  | 'GridStripeButton'
  | 'GridEcommerceButton'
  | 'SearchBar';

export type SiteBackgroundType =  'path' | 'origin' | 'color' | 'gradient' | 'image' | 'video';

export interface SiteBackgroundGradient {
  /**
  * @minimum 0
  * @maximum 360
  */
  angle: number;
  isAnimated: boolean;
  colors: { value: string }[];
}

export type SiteEcommerceShoppingCart = {
  translations: Record<string, string>;
  lang?: string;
  [k: string]: unknown;
}

export type SiteEcommerceSeoChanges = {
  id: string;
  seo_settings: {
    title?: string;
    noindex?: boolean;
    keywords?: string[];
    description?: string;
    focusKeyword?: string;
    ogImageOrigin?: string;
    ogImagePath?: string;
    ogImageAlt?: string;
    slug?: string;
  }
}

export type EcommerceSortingValue = '' | 'order=ASC&sort_by=price' | 'order=DESC&sort_by=price' | 'order=DESC&sort_by=created_at';

export type SiteBlockSettings = {
  styles?: {
    [k: string]: unknown;
  };
  categories?: string[];
  logoSvg?: string;
}

export type SiteEcommerceSortingValue = '' | 'order=ASC&sort_by=price' | 'order=DESC&sort_by=price' | 'order=DESC&sort_by=created_at' | 'order=ASC&sort_by=collection_order';

export interface SiteBlock {
  type: SiteBlockType;
  settings: SiteBlockSettings & {
    [k: string]: unknown;
  };
  slot?: string;
  background?: {
    path?: string | null;
    origin?: string | null;
    color?: string;
    gradient?: SiteBackgroundGradient;
    current?: SiteBackgroundType;
    'overlay-opacity'?: string;
    alt?: string;
		video?: {
			videoSrc: string;
			videoThumbnailSrc: string;
		}
    [k: string]: unknown;
  };
  components?: string[];
  zindexes?: string[];
  desktop?: {
    minHeight?: number;
    [k: string]: unknown;
  };
  mobile?: {
    minHeight?: number;
    [k: string]: unknown;
  };
  initialBlockId?: string;
  locale?: string;
  shouldSnapToGuides?: boolean;
  shouldSnapToElements?: boolean;
  snapRowGap?: number;
  snapRowHeight?: number;
  isMobileAutoPositioningEnabled?: boolean;
  isQuantityPickerEnabled?: boolean;
  attachment?: 'unset' | 'fixed';
  htmlId?: string;
  isButtonEnabled?: boolean;
  buttonDisplay?: string;
  aiData?: {
    type?: string;
    [k: string]: unknown;
  };
  items?: {
    type: string;
    content: string;
    styles: {
        [k: string]: unknown;
    }
  }[],
	productSorting?: {
		enabled: boolean;
		textColor: string;
		sortingOptions: {
			id: string;
			value: SiteEcommerceSortingValue;
			label: string;
			isEnabled: boolean;
		}[]
	},
	productIds?: string[];
	productCategoryId?: string;
	isCategoryListEnabled?: boolean;
	isFullWidth?: boolean;
	isTotalProductCountShown?: boolean;
	columnCount?: number;
	productsPerPage?: number;
	scrollBehaviour?: 'pagination' | 'scroll';
}

export interface SitePageMeta {
  noindex?: boolean;
  title?: string;
  password?: string;
  ogImageAlt?: string | null;
  ogImagePath?: string | null;
  ogImageOrigin?: string | null;
  passwordDesign?: string;
  passwordBackText?: string;
  passwordButtonText?: string;
  passwordHeadingText?: string;
  passwordSubheadingText?: string;
  passwordPlaceholderText?: string;
}

export interface SitePage {
  type?: string;
  locale?: string;
  initialPageId?: string;
  name?: string;
  slug?: string;
  date?: string;
  isDraft?: boolean;
  isScheduled?: boolean;
  categories?: string[];
  coverImageAlt?: null | string;
  coverImagePath?: string;
  coverImageOrigin?: string;
  minutesToRead?: string;
  meta?: SitePageMeta;
  footerSlotIsHidden?: boolean;
  pageId?: string;
  blocks?: string[];
  productId?: string | number;
}

export const LINK_TYPE = {
	EXTERNAL: 'external',
	DOWNLOAD: 'download',
	ANCHORED_SECTION: 'anchored-section',
	EMAIL: 'email',
	PHONE: 'phone',
	PAGE: 'page',
} as const;

export type LinkType = (typeof LINK_TYPE)[keyof typeof LINK_TYPE];

export const NAVIGATION_TYPE = {
  LINK: 'Link',
  PAGE: 'Page',
  FOLDER: 'Folder',
  HOMEPAGE: 'Homepage',
} as const;

export type NavigationType = (typeof NAVIGATION_TYPE)[keyof typeof NAVIGATION_TYPE];

export interface SiteNavItem {
  linkType?: NavigationType;
  locale?: string;
  href?: string;
  name?: string;
  target?: string;
  rel?: string;
  innerLinkType?: string;
  innerLinkPageId?: string;
  subItems?: unknown[];
  navItemId?: string;
  linkedPageId?: string;
  isHidden?: boolean;
}

export type SitePages = Record<string, SitePage>;
export type SiteBlocks = Record<string, SiteBlock>;
export type SiteElement = SiteElementGridButton | SiteElementGridStripeButton | SiteElementGridEcommerceButton | SiteElementGridMap | SiteElementGridVideo | SiteElementGridTextBox | SiteElementGridShape | SiteElementGridImage | SiteElementGridInstagramFeed | SiteElementGridSocialIcons | SiteElementGridGallery | SiteElementGridEmbed | SiteElementSearchBar | SiteElementGridForm;
export type SiteElements = Record<string, SiteElement>;
export interface SiteLanguage {
  metaTitle?: string;
  name?: string;
  homePageId: string;
  pages: SitePages;
  blocks: SiteBlocks;
  elements: SiteElements;
  nav: SiteNavItem[];
  isNavHidden: boolean;
  locale: string;
  cookieBannerAcceptText?: string;
  cookieBannerDeclineText?: string;
  cookieBannerDisclaimer?: string;
  blogReadingTimeText?: string;
  orderPosition?: number;
}

export interface SiteMeta {
  GAId?: string;
  shouldAddWWWPrefixToDomain: boolean;
  version: number;
  template: string;
  isFirstLoad?: boolean;
  ecommerceStoreId?: string;
  demoEcommerceStoreId?: string;
  customMeta?: string;
  customBodyElements?: string;
  customHeadElements?: string;
  typographyStylesId?: string;
  colorSetId?: string;
  buttonSetId?: string;
  facebookPixel?: string;
  googleAdSense?: string;
  googleAdMob?: string;
  googleTagManager?: string;
  googleAnalytics?: string;
  hotjar?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaHtmlLanguage?: string;
  ogImagePath?: null | string;
  ogImageOrigin?: null | string;
  ogImageAlt?: null | string;
  faviconPath?: string;
  faviconOrigin?: string;
  curatedTemplate?: {
    link?: string;
    author?: string;
  };
  stripePublicApiKey?: string;
  whatsAppNumber?: string;
  whatsAppMessage?: string;
  isCookieBarEnabled?: boolean;
  jivoChat?: string;
  qa?: boolean;
  ecommerceType?: string;
  defaultLocale?: string;
  isLayout: boolean;
  migrationToLayoutModifications?: {
    splitSlideshows?: Record<string, any>;
    imageObjectFitRemoved?: boolean;
    unsnappedElementSections?: Record<string, any>;
  };
  isPrivateModeActive: boolean;
  aiWebsiteType?: string;
  isCustomCodeDisabled?: boolean;
  payseraVerificationCode?: string;
  isExistingStoreWithDynamicTemplate?: boolean;
}

export interface SiteStyles {
  h1: SiteTypographyStyles;
  h2: SiteTypographyStyles;
  h3: SiteTypographyStyles;
  h4: SiteTypographyStyles;
  h5: SiteTypographyStyles;
  h6: SiteTypographyStyles;
  'body-small': SiteTypographyStyles;
  body: SiteTypographyStyles;
  'body-large': SiteTypographyStyles;
  'nav-link': SiteTypographyStyles;
  font: {
    primary: string;
    secondary: string;
  };
  'grid-button-primary': SiteGridButtonStyle;
  'grid-button-secondary': SiteGridButtonStyle;
}

export type SiteForms = Record<string, { token: string; }>;

export type SiteLanguages = Record<string, SiteLanguage>;

export type SiteBlogCategory = { name: string; };
export type SiteBlogCategories = Record<string, SiteBlogCategory>;

export type SiteNavigationBlock = Record<string, unknown>;

export type SiteUser = Record<string, unknown>;

export interface SiteFont {
  family: string;
  type: string;
  weights?: (string | number)[];
}

export interface SiteBuilderCompletedSteps {
  hasChangedLogo?: boolean;
}

export interface SiteData {
  builderCompletedSteps?: SiteBuilderCompletedSteps;
  meta: SiteMeta;
  domain?: string | null;
  freeDomain?: string;
  blogCategories: SiteBlogCategories;
  languages: SiteLanguages;
  styles: SiteStyles;
  forms: SiteForms;
  navigationBlock?: SiteNavigationBlock;
  user?: SiteUser;
  siteId?: string;
  fonts?: SiteFont[];
  ecommerceShoppingCart?: SiteEcommerceShoppingCart;
  ecommerceSeoChanges?: SiteEcommerceSeoChanges[];
}

export interface SiteLanguageSwitcherLanguage {
	country: string;
	flagPath: string;
	href: string;
	isHidden: boolean;
	locale: string;
	name: string;
}

export type UpsertRegionCountry = {
	country_code: string
	provinces: string[]
}
