import { DEMO_ECOMMERCE_STORE_ID } from '@zyro-inc/site-modules/constants/ecommerce';

export const getStoreId = (siteMeta) => {
	if (siteMeta?.ecommerceStoreId) {
		return siteMeta.ecommerceStoreId;
	}

	if (siteMeta?.demoEcommerceStoreId === 'store_01G0D7VX5QVK0XZ6QEZT6CQBMM') {
		return siteMeta?.demoEcommerceStoreId;
	}

	return DEMO_ECOMMERCE_STORE_ID;
};
