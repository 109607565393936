<script setup lang="ts">
import {
	getOptimizedSrc,
	getGridItemSrcset,
	getGridItemSizes,
} from '@zyro-inc/site-modules/utils/getSrcsets';

import {
	computed,
	onMounted,
	ref,
	watch,
} from 'vue';
import { constructEmbedUrl } from '@zyro-inc/site-modules/components/elements/video/extractVideoProps';
import {
	PROVIDERS,
	URL_PARAMS,
	URL_REG_EXP,
} from '@zyro-inc/site-modules/components/elements/video/constants';
import { EcommerceProductMediaType } from '@zyro-inc/site-modules/constants/ecommerce';

interface Props {
	mediaType?: string;
	alt?: string;
	src: string;
	isEager?: boolean;
	objectFit?: string;
	imageRatio?: string;
	width?: number;
	height?: number;
	enableSrcset?: boolean;
	imageOrigin?: string;
	isLossless?: boolean;
	siteId?: string;
	secondaryImageSrc?: string | null;
	isVideoAutoplayDisabled?: boolean;
	isVideoDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	mediaType: EcommerceProductMediaType.IMAGE,
	isEager: false,
	objectFit: 'cover',
	width: 0,
	height: 0,
	enableSrcset: false,
	imageOrigin: 'other',
	isLossless: false,
	siteId: '',
});

const imageUrl = ref(props.src);
const srcset = ref<string>();
const isLoaded = ref(false);

const sizes = computed(() => (props.enableSrcset ? getGridItemSizes(props.width) : undefined));

const getVideoUrl = (url: string) => {
	const youtubeUrlId = url.match(URL_REG_EXP[PROVIDERS.YOUTUBE])?.[1];

	return constructEmbedUrl(youtubeUrlId, PROVIDERS.YOUTUBE, {
		[URL_PARAMS.AUTOPLAY]: 1,
		[URL_PARAMS.CONTROLS]: 1,
	}, url);
};

const getMediaImageUrl = (url: string) => {
	if (props.mediaType === EcommerceProductMediaType.VIDEO) {
		const youtubeUrlId = url.match(URL_REG_EXP[PROVIDERS.YOUTUBE])?.[1];

		return `https://i.ytimg.com/vi_webp/${youtubeUrlId}/hqdefault.webp`;
	}

	return url;
};

const getOptimizedImageUrl = (url: string) => {
	const mediaUrl = getMediaImageUrl(url);

	return getOptimizedSrc(props.imageOrigin, mediaUrl, props.siteId, {
		width: props.width,
		height: props.height,
		shouldContain: props.objectFit === 'contain',
		isLossless: props.isLossless,
	});
};

const getSrcSet = (url: string) => {
	if (!props.enableSrcset) {
		// eslint-disable-next-line unicorn/no-useless-undefined
		return undefined;
	}

	const mediaUrl = getMediaImageUrl(url);

	return getGridItemSrcset(props.imageOrigin, mediaUrl, props.siteId, {
		width: props.width,
		height: props.height,
		shouldContain: props.objectFit === 'contain',
		isLossless: props.isLossless,
	});
};

const preloadSecondaryImage = () => {
	if (!props.secondaryImageSrc) {
		return;
	}

	// Preload secondary image, so it's instant on hover
	const img = new Image();
	const imgSrcset = getSrcSet(props.secondaryImageSrc);

	img.src = getOptimizedImageUrl(props.secondaryImageSrc);

	if (imgSrcset) {
		img.srcset = imgSrcset;
	}
};

watch(() => props.imageRatio, (newValue, oldValue) => {
	// update image url for updated image ratio aka height otherwise image will be cropped improperly
	if (newValue !== oldValue) {
		imageUrl.value = getOptimizedImageUrl(props.src);
		srcset.value = getSrcSet(props.src);
	}
});

// `src` can change on product hover or when changing image hover effect value
watch(() => props.src, () => {
	imageUrl.value = getOptimizedImageUrl(props.src);
	srcset.value = getSrcSet(props.src);
});

watch(() => props.secondaryImageSrc, () => {
	preloadSecondaryImage();
});

onMounted(() => {
	imageUrl.value = getOptimizedImageUrl(props.src);
	srcset.value = getSrcSet(props.src);

	preloadSecondaryImage();
});
</script>

<template>
	<Transition
		appear
		name="fade"
		mode="out-in"
	>
		<iframe
			v-if="!isVideoDisabled && mediaType === EcommerceProductMediaType.VIDEO && !isVideoAutoplayDisabled"
			:src="getVideoUrl(src)"
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen
			:width="140"
			:height="140"
		/>
		<img
			v-else
			v-bind="$attrs"
			:loading="isEager ? 'eager': 'lazy'"
			:src="imageUrl"
			:alt="alt"
			:srcset="srcset"
			:sizes="sizes"
			class="ecommerce-product-image"
			:class="{ 'ecommerce-product-image--loading': !isLoaded }"
			@load="isLoaded = true"
			@error="isLoaded = true"
		>
	</Transition>
</template>

<style lang="scss">
.ecommerce-product-image {
	&--loading {
		visibility: hidden;
	}
}
</style>
